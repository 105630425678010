import React, { useCallback, useEffect, useRef } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { useLocation } from 'react-router-dom'
import { Localization } from './helpers/BoundLocalization.js'
import { logErrorToDatadog } from './utilities/datadogActions.js'

/**
 * @typedef {object} FallbackProps
 * @prop {Error} error
 * @prop {function} resetErrorBoundary
 */

/** @callback ComponentForFallback
 *  @param {FallbackProps} fallbackProps
 */

/**
 * @typedef {object} ErrorBoundaryProps
 * @prop {import('./log/logger.js').MFELogger} [logger] - a logger instance created by createMFELogger
 * @prop {any} [children] - children to render
 * @prop {ComponentForFallback} [FallbackComponent] - a React component which renders if a child component throws an error (a default fallback component is provided)
 * @prop {(arg: ?) => void} [onReset] - a function called if the error boundary error is reset via the FallbackComponent's resetErrorBoundary function
 * @prop {(arg: ?) => void} [onResetKeysChange] - a function called if the error boundary error is reset via the resetKeys
 * @prop {(error: Error, info: object) => void} [onError] - a function called if there is an error captured by the error boundary
 * @prop {Array.<number|string|boolean>} [resetKeys] - an array of keys which will reset the error boundary if they change
 * @prop {(props: FallbackProps) => void} [fallbackRender] - an inline render function, receives the same arguments/props as a FallbackComponent
 */

/**
 * Wraps children in a React error boundary component, a wrapped re-export of react-error-boundary's ErrorBoundary component
 * @param {ErrorBoundaryProps} props
 */
export function ErrorBoundary(props) {
  const {
    logger,
    FallbackComponent = DefaultFallbackComponent,
    children,
    onError,
    ...rest
  } = props

  const loggerRef = useRef(logger)

  useEffect(() => {
    if (logger && loggerRef.current !== logger) {
      console.warn(
        'Logger instances should be created outside of React components and should not change; check the logger prop on the offending ErrorBoundary'
      )
    }
  }, [logger])

  const logToDatadog = useCallback(
    (error, info) => {
      const additionalScope = {
        contexts: {
          react: info.componentStack,
        },
      }

      if (logger) {
        logger.error(error, additionalScope)
      } else {
        logErrorToDatadog(error, additionalScope)
      }

      if (onError) {
        onError(error, info)
      }
    },
    [logger, onError]
  )

  return (
    <ReactErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={logToDatadog}
      {...rest}
    >
      {children}
    </ReactErrorBoundary>
  )
}

export const __fallback_text_for_testing__ =
  'An error has occurred and we are working to resolve the issue. To continue with your work, try refreshing this browser page.'

function DefaultFallbackComponent() {
  const location = useLocation()

  const pendoClassName = 'subpage-error'

  return (
    <div
      className={`${pendoClassName} pt-4 flex flex-col items-center`}
      data-testid={`subpage-error-${location.pathname.split('/')[1]}`}
      role="alert"
    >
      <h1 className="h2 mr-8 ml-8">
        <Localization
          messageKey="quicksilver.loading.error"
          fallback={__fallback_text_for_testing__}
        />
      </h1>
    </div>
  )
}

export function ErrorBoundaryCompatChanges(props) {
  return (
    <ErrorBoundary
      {...props}
      FallbackComponent={
        props.FallbackComponent || DefaultFallbackComponentCompatChanges
      }
    />
  )
}

function DefaultFallbackComponentCompatChanges() {
  const location = useLocation()

  const pendoClassName = 'subpage-error'

  return (
    <div
      className={`${pendoClassName} pt-4 flex flex-col items-center`}
      data-testid={`subpage-error-${location.pathname.split('/')[1]}`}
      role="alert"
    >
      <h1 className="h2 mr-8 ml-8">
        <Localization
          messageKey="quicksilver.loading.error"
          fallback={__fallback_text_for_testing__}
        />
      </h1>
    </div>
  )
}
