import createError from './createError.js'
import getMFEVersion from './getMFEVersion.js'
import { shouldLog, isInDevMode } from './shouldLog.js'

if (isInDevMode() && shouldLog() === false) {
  console.warn(
    `Errors/actions will not be logged to Datadog in development mode. If you wish to change this behavior and log errors to Datadog while developer tools are enabled, set "wf:log-with-devtools" to true in localstorage`
  )
}

export const severity = {
  info: 'Info',
  debug: 'Debug',
  warning: 'Warning',
}

let systemJSFileLoadingTimeout = null
const systemJSFileLoadErrors = new Set()

export function logErrorToDatadog(error, additionalScope = {}) {
  const devMode = isInDevMode()
  const { tags = {}, name = null } = additionalScope
  const ensuredError = createError(error)

  if (devMode && name === null && tags['mfe-name'] === undefined) {
    console.warn(
      'Sending errors to Datadog without a MFE name is strongly discouraged'
    )
  }
  if (additionalScope.version === undefined && name !== null) {
    additionalScope.version = getMFEVersion(name)
  }

  // name was too generic
  additionalScope.mfeName = additionalScope.name
  console.error(ensuredError)
  // In some situations we don't log errors to datadog but only put them in the console as above (local development).
  if (shouldLog()) {
    const isSystemJSLoadingError = getIsSystemJSLoadingError(error)

    if (isSystemJSLoadingError) {
      systemJSFileLoadErrors.add(isSystemJSLoadingError.split('/').pop())
      temporarilyThrottleSystemJSLoadingErrors(additionalScope)
    } else {
      logError(error, additionalScope)
    }
  }
}

const getShellContext = (() => {
  const getValuesFromShell = () => {
    const runtime = window['exc-module-runtime']
    return Promise.all([
      runtime.user().get('imsOrg'),
      runtime
        .user()
        .get('imsProfile')
        .then((imsProfile) => imsProfile.userId),
      runtime.shell().get('imsEnvironment'),
    ]).then(([imsOrgId, imsUserId, imsEnvironment]) => ({
      imsOrgId,
      imsUserId,
      imsEnvironment,
    }))
  }
  let cachedShellContext
  return async () => {
    if (!cachedShellContext) {
      cachedShellContext = getValuesFromShell()
    }
    return cachedShellContext
  }
})()

function logError(error, additionalScope) {
  if (window.DD_RUM) {
    window.DD_RUM.addError(error, additionalScope)
  } else if (window['exc-module-runtime']) {
    const { metrics: Metrics } = window['exc-module-runtime']
    const metrics = Metrics.create(additionalScope.mfeName)
    getShellContext().then((shellContext) => {
      metrics.error(error.toString(), {
        error,
        ...additionalScope,
        ...shellContext,
      })
    })
  }
}

export function logAction(message, data, logLevel) {
  if (window.DD_RUM) {
    window.DD_RUM.addAction(message, data)
  } else if (window['exc-module-runtime']) {
    const { metrics: Metrics } = window['exc-module-runtime']
    const metrics = Metrics.create(data?.meta?.name || 'wf.untitled')
    const severityToMethodMapping = {
      [severity.info]: 'info',
      [severity.debug]: 'debug',
      [severity.warning]: 'warn',
    }
    const method =
      severityToMethodMapping[logLevel || data.meta.level] || 'info'
    getShellContext().then((shellContext) => {
      metrics[method](message, {
        ...shellContext,
        ...data,
      })
    })
  }
}

const SYSTEM_JS_FILE_LOAD_ERROR =
  /Error\sloading\s(.*)\s(from\s.*)?\(SystemJS\sError#3/

function getIsSystemJSLoadingError(error) {
  return error.message?.match(SYSTEM_JS_FILE_LOAD_ERROR)?.[1]
}

function temporarilyThrottleSystemJSLoadingErrors(additionalScope) {
  clearTimeout(systemJSFileLoadingTimeout)

  systemJSFileLoadingTimeout = setTimeout(() => {
    const fileLoadingErrors = new Error(
      `SystemJS Error#3 error loading files: (${Array.from(
        systemJSFileLoadErrors
      ).join(', ')})`
    )
    logError(fileLoadingErrors, additionalScope)
  }, 100)
}

export function sendBreadcrumbToDatadog({
  name = null,
  version = null,
  message,
  additionalData = {},
  defaultLevel = severity.info,
}) {
  const devMode = isInDevMode()
  let ensuredVersion = version
  const { level } = additionalData
  if (devMode && name === null) {
    console.warn(
      'Sending actions to Datadog without an identifying name is strongly discouraged'
    )
  }
  if (ensuredVersion === null && name !== null) {
    ensuredVersion = getMFEVersion(name)
  } else if (devMode && ensuredVersion === null && name === null) {
    console.warn(
      'Sending actions to Datadog without a mfe version is strongly discouraged'
    )
  }
  if (level === severity.warning || defaultLevel === severity.warning) {
    console.warn(message)
  }
  // In some situations we don't send messages to datadog but only put them in the console as above (local development).
  if (shouldLog()) {
    logAction(message, {
      ...additionalData,
      meta: {
        level: level || defaultLevel,
        name,
        version,
      },
    })
  }
}
