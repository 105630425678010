export default function createError(err) {
  if (err instanceof Error) return err
  if (typeof err === 'string') return new Error(err)
  if (typeof err === 'object') {
    try {
      return new Error(JSON.stringify(err))
    } catch (e) {
      return e
    }
  }
  return new Error(`Cannot create error with type: ${typeof err}`)
}
