import { BrowserClient, createBoundLocalization } from '@wf-mfe/localize-react'

export const redrockClient = new BrowserClient({
  namespace: 'redrock',
})

const { Localization, LoadLocalizations, useLocalization } =
  createBoundLocalization(redrockClient)

export { Localization, LoadLocalizations, useLocalization }
