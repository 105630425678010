import {
  sendBreadcrumbToDatadog,
  logErrorToDatadog,
} from '../utilities/datadogActions.js'
import getMFEVersion from '../utilities/getMFEVersion.js'

/**
 * @typedef {Object} MFELoggerConfig
 * @property {string} name  scope name
 */

/**
 * Returns a new MFE logger that provides methods for interacting with Datadog
 * @param {MFELoggerConfig} config
 * @returns {MFELogger}
 */
export function createMFELogger(config) {
  return new MFELogger(config)
}

const severity = {
  info: 'Info',
  debug: 'Debug',
  warning: 'Warning',
}

export class MFELogger {
  /**
   * @param {MFELoggerConfig} config
   */
  constructor(config) {
    const { name } = config
    this.name = name
    this.version = getMFEVersion(name)
  }

  /**
   * A proxy to {@link trace}.
   * @param {string} message
   * @param {object} [additionalData]
   */
  trace(message, additionalData) {
    this.breadcrumb(message, additionalData, severity.debug)
  }

  /**
   * Sends a log to Datadog with the level of `debug`
   * @param {string} message
   * @param {object} [additionalData]
   */
  debug(message, additionalData) {
    this.breadcrumb(message, additionalData, severity.debug)
  }

  /**
   * Sends a log to Datadog with the level of `info`
   * @param {string} message
   * @param {object} [additionalData]
   */
  info(message, additionalData) {
    this.breadcrumb(message, additionalData, severity.info)
  }

  /**
   * Sends a log to Datadog with the level of `warning`
   * @param {string} message
   * @param {object} [additionalData]
   */
  warn(message, additionalData) {
    this.breadcrumb(message, additionalData, severity.warning)
  }

  /**
   * Sends a log to Datadog with the level of `error`.
   * It will work best if you send in an error rather than a string, but it will work with a string
   * @param {any} error
   * @param {object} [additionalScope]
   */
  error(error, additionalScope) {
    this.logError(error, additionalScope)
  }

  /**
   * A proxy to {@link error}.
   * @param {any} error
   * @param {object} [additionalScope]
   */
  fatal(error, additionalScope) {
    this.logError(error, additionalScope)
  }

  /**
   * Add a Datadog breadcrumb
   * @param {string} message
   * @param {object} [additionalData]
   * @param {string} [defaultLevel]  error level. Defaults to 'info'.
   */
  breadcrumb(message, additionalData = {}, defaultLevel = severity.info) {
    const normalizedBreadcrumb = {
      message,
      additionalData,
      defaultLevel,
      name: this.name,
      version: this.version,
    }
    sendBreadcrumbToDatadog(normalizedBreadcrumb)
  }

  /**
   * Logs an error to Datadog
   * @param {any} error
   * @param {object} [additionalScope]
   */
  logError(error, additionalScope) {
    const normalizedAdditionalScope = {
      name: this.name,
      version: this.version,
      ...additionalScope,
    }
    logErrorToDatadog(error, normalizedAdditionalScope)
  }
}
