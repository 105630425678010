import { logRumEvent } from './logRumEvent.js'
import { getShellMetrics } from './unifiedShell.js'

let hasLoggedIframeStartTime = false

export async function logPerformanceMetric(markOrMeasure) {
  if (
    window.DD_RUM !== undefined &&
    window.NWE_RUM?.shouldLogPerformanceTiming?.()
  ) {
    const shellMetrics = await getShellMetrics()

    const metric =
      typeof markOrMeasure === 'string'
        ? performance.getEntriesByName?.(markOrMeasure)?.[0]
        : markOrMeasure

    if (metric) {
      let metricName = metric.name

      if (metric.entryType === 'mark') {
        const workfrontIframeStartTime =
          shellMetrics?.loadTimes?.iframeFetchStart ?? 0

        const actualStartTime = metric.startTime + workfrontIframeStartTime

        if (workfrontIframeStartTime !== 0) {
          if (!hasLoggedIframeStartTime) {
            hasLoggedIframeStartTime = true
            logRumEvent('nwe_iframe_start_time', {
              nwe_iframe_start_time: workfrontIframeStartTime,
            })
          }

          const shellMetricName = `shell_${metric.name}`
          logRumEvent(shellMetricName, { [shellMetricName]: actualStartTime })
        }

        // Will eventually be the same as shell_ once all customers are migrated
        // TODO remove the shell_ variant above once this is the case
        const actualMetricName = `actual_${metric.name}`
        logRumEvent(actualMetricName, { [actualMetricName]: actualStartTime })

        // Workfront-only, ignoring Shell
        logRumEvent(metricName, { [metricName]: metric.startTime })
      } else {
        logRumEvent(metricName, { [metricName]: metric.duration })
      }
    }
  }
}

export function logPerformanceMetrics() {
  const timing = performance.getEntriesByType('navigation')[0]

  // fake mark, but allows us to easily add/send the shell timing as well
  logPerformanceMetric({
    name: 'nwe_ttlb',
    startTime: timing.responseEnd - timing.startTime,
    entryType: 'mark',
  })

  performance
    .getEntries()
    .filter((markOrMeasure) => markOrMeasure.name.startsWith('nwe_'))
    .forEach((metric) => {
      logPerformanceMetric(metric)
    })
}
