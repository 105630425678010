import { useEffect, useRef } from 'react'

let performanceState = {}

// exported for testing
export const handlePerformanceStateAction = (action) => {
  if (action === 'INIT') {
    performanceState = {
      lastPageVisibilityEventTime: 0,
      lastNavigationEventTime: 0,
      initialTimingCaptured: false,
      canLog: true,
    }
  }

  if (action === 'HISTORY_CHANGE') {
    // I don't like having to have this check here
    // this is to prevent the automatic redirects from
    // /project/123/ to /project/123/updates which happens a few seconds in
    // from impacting the initial load numbers
    if (performanceState.initialTimingCaptured) {
      resetPerformanceState()
    }
  }

  if (action === 'SECONDARY_NAV_CLICK') {
    performanceState = {
      ...performanceState,
      // If a user lands on say the task list but clicks away before it loads we don't
      // count the area they go to as the initial navigation any longer
      initialTimingCaptured: true,
      lastNavigationEventTime: performance.now(),
    }
  }

  if (action === 'PAGE_VISIBILITY_CHANGE') {
    performanceState = {
      ...performanceState,
      lastPageVisibilityEventTime: performance.now(),
    }
  }

  if (action === 'TIMING_LOGGED') {
    performanceState = {
      ...performanceState,
      canLog: false,
      initialTimingCaptured: true,
    }
  }

  return performanceState
}

/**
 * @returns {number}
 */
export const getLastNavigationEventTime = () =>
  performanceState.lastNavigationEventTime
/**
 * @returns {void}
 */
export const resetPerformanceState = () => {
  performanceState = {
    ...performanceState,
    // not sure if it was a bot, but sometimes we'd get
    // large chunks of events in Datadog within a few ms
    // we can only log once per history event now :fingers_crossed:
    canLog: true,
    lastNavigationEventTime: performance.now(),
  }
}

handlePerformanceStateAction('INIT')

window.addEventListener('popstate', () => {
  handlePerformanceStateAction('HISTORY_CHANGE')
})

window.addEventListener('visibilitychange', () => {
  handlePerformanceStateAction('PAGE_VISIBILITY_CHANGE')
})

// SecondaryNav calls this directly in its onClick handler
/**
 * @returns {void}
 */
export const reportUserNavigationForPerformanceMetrics = () => {
  handlePerformanceStateAction('SECONDARY_NAV_CLICK')
}

const localLogging = localStorage.getItem('qsperftiming')
/**
 * @param {string} metricName
 * @param {object} data
 * @returns {void}
 */
export const logMetricToConsole = (metricName, data) => {
  if (localLogging) {
    console.groupCollapsed(
      `DEPRECATED MEASUREMENT - ${metricName}: ${Math.round(data.time)}ms`
    )
    console.log(data)
    console.groupEnd()
  }
}

/**
 * @deprecated Please use logPageLoadTime instead
 */
export const logCustomPageTiming = ({
  time,
  isShim,
  fromCache = false,
  pageContentStartTime,
  childrenDebug, // temporary for debugging purposes
  timingDebug, // temporary for debugging purposes
}) =>
  logPageLoadTime(time, {
    isShim,
    fromCache,
    pageContentStartTime,
    childrenDebug,
    timingDebug,
  })

/**
 * @param {number} time
 * @param {object} customData
 * @returns {void}
 */
export const logPageLoadTime = (time, customData) => {
  const {
    canLog,
    initialTimingCaptured,
    lastNavigationEventTime,
    lastPageVisibilityEventTime,
  } = performanceState

  const timeToLog = time - lastNavigationEventTime

  const isInitialTiming = lastNavigationEventTime === 0

  const timeDataIsBad =
    (!initialTimingCaptured && lastPageVisibilityEventTime > 0) ||
    lastNavigationEventTime < lastPageVisibilityEventTime ||
    isNaN(timeToLog) ||
    timeToLog < 0

  if (canLog && !timeDataIsBad && !document.hidden) {
    const metricName = `qsperf_${
      isInitialTiming ? 'initial_load' : 'route_change'
    }`
    const data = {
      time: timeToLog,
      reportedTime: time,
      ...customData,
    }

    logMetricToConsole(metricName, data)

    handlePerformanceStateAction('TIMING_LOGGED')
  }
}

// we'll basically check for 30 seconds to see if the content loads
const INITIAL_MEASUREMENT_CYCLES = 70
const MEASUREMENT_INTERVAL = 400
// 15% more nodes than before qualifies as a LCP event
const LCP_CHANGE_THRESHOLD = 1.15

/**
 * @param {boolean} isShim
 * @returns {any} - refForPerformanceMeasurements
 */
export function useCustomPerformanceMetrics(isShim) {
  const refForPerformanceMeasurements = useRef()

  useEffect(() => {
    if (window.DD_RUM === undefined && !localLogging) {
      return () => {}
    }

    const pageContentStartTime = performance.now()

    function iframeLargestContentfulPaintListener(msg) {
      if (msg.data.source === 'qs-lcp') {
        logCustomPageTiming({
          time: pageContentStartTime + msg.data.time,
          pageContentStartTime,
          isShim: true,
        })

        window.removeEventListener(
          'message',
          iframeLargestContentfulPaintListener
        )
      }
    }

    if (isShim) {
      window.addEventListener('message', iframeLargestContentfulPaintListener)

      return () =>
        window.removeEventListener(
          'message',
          iframeLargestContentfulPaintListener
        )
    } else if (refForPerformanceMeasurements.current) {
      // custom algorithm to approximate the largest-contentful-paint metric found in Chrome
      // this is IE11 compatible, plus it allows us to track the metric more than once
      // for non-initial page loads on route change
      // RedRock has similar code that fires the qs-lcp message above
      let numberOfChildrenInLastPageSizeIncease = 1
      let timeAtLastPageSizeIncrease
      let childrenDebug = []
      let timingDebug = []

      let cyclesRemaining = INITIAL_MEASUREMENT_CYCLES
      const interval = setInterval(() => {
        requestAnimationFrame(() => {
          if (refForPerformanceMeasurements.current == null) {
            clearInterval(interval)
            return
          }

          const iframeChild =
            refForPerformanceMeasurements.current.querySelector('iframe')

          // mainly for the proofing page and not as accurate,
          // almost every other iframe is a KaminoShim and is handled above
          if (iframeChild) {
            iframeChild.addEventListener('load', () => {
              logCustomPageTiming({
                time: performance.now(),
                pageContentStartTime,
                isShim: true,
              })
            })

            cyclesRemaining = 0
            clearInterval(interval)
          } else {
            const currentNumberOfChildren =
              refForPerformanceMeasurements.current.querySelectorAll('*').length

            childrenDebug.push(currentNumberOfChildren)
            let now = performance.now()
            timingDebug.push(Math.round(now))

            const pageSizeHasGrownSignificantly =
              currentNumberOfChildren / numberOfChildrenInLastPageSizeIncease >=
              LCP_CHANGE_THRESHOLD

            if (pageSizeHasGrownSignificantly) {
              numberOfChildrenInLastPageSizeIncease = currentNumberOfChildren
              timeAtLastPageSizeIncrease = now

              cyclesRemaining = getRemainingCyclesForChildrenUpdate(
                currentNumberOfChildren,
                cyclesRemaining
              )
            }

            cyclesRemaining--
            if (cyclesRemaining <= 0) {
              clearInterval(interval)

              logCustomPageTiming({
                time: timeAtLastPageSizeIncrease ?? now,
                pageContentStartTime,
                isShim,
                // these 2 are temporary for debugging in Datadog
                childrenDebug: childrenDebug.join('-'),
                timingDebug: timingDebug.join('-'),
              })
            }
          }
        })
      }, MEASUREMENT_INTERVAL)

      return () => clearInterval(interval)
    }
  }, [isShim])

  return refForPerformanceMeasurements
}

const MAJOR_DOM_SIZE_INCREASE = 1000
const MAX_CYCLES_REMAINING_AFTER_MAJOR_INCRASE = 1
const MODERATE_DOM_SIZE_INCREASE = 100
const MAX_CYCLES_REMAINING_AFTER_MODERATE_INCRASE = 3
const MINOR_DOM_SIZE_INCREASE = 25
const MAX_CYCLES_REMAINING_AFTER_MINOR_INCRASE = 6

function getRemainingCyclesForChildrenUpdate(
  numberOfChildren,
  cyclesRemaining
) {
  let newCyclesRemaining = cyclesRemaining

  if (numberOfChildren > MAJOR_DOM_SIZE_INCREASE) {
    newCyclesRemaining = MAX_CYCLES_REMAINING_AFTER_MAJOR_INCRASE
  } else if (numberOfChildren > MODERATE_DOM_SIZE_INCREASE) {
    newCyclesRemaining = MAX_CYCLES_REMAINING_AFTER_MODERATE_INCRASE
  } else if (numberOfChildren > MINOR_DOM_SIZE_INCREASE) {
    newCyclesRemaining = MAX_CYCLES_REMAINING_AFTER_MINOR_INCRASE
  }

  return Math.min(newCyclesRemaining, cyclesRemaining)
}
