/**
 * Initialize datadog global contexts. Will be called by mfe root-config so that global contexts are initialized for all apps
 * Microfrontends do not need to call this function.
 *
 * @returns {Promise<unknown>}
 */
export function initalizeDataDogGlobalContexts() {
  if (!window.DD_RUM) {
    return Promise.resolve()
  } else {
    return setDDGlobalContexts()
  }
}

function setDDGlobalContexts() {
  return Promise.all([
    System.import('@wf-mfe/auth'),
    System.import('rxjs'),
  ]).then(([auth, rxjs]) => {
    rxjs
      .combineLatest([auth.getCurrentCustomer$(), auth.getCurrentUser$()])
      .subscribe(
        ([customer, user]) => {
          const contextMethod =
            window.DD_RUM.addRumGlobalContext ??
            window.DD_RUM.setGlobalContextProperty

          contextMethod('customer', {
            id: customer.ID,
            name: customer.name,
            domain: customer.domain,
            uumTenantID: customer.uumTenantID,
            isUsingSylon: customer.isUsingSylon,
          })
          // Set domain and customerID as a tag so it easily filterable
          contextMethod('customer.id', customer.ID)
          contextMethod('customer.domain', customer.domain)

          contextMethod('user', {
            id: user.ID,
            email: user.emailAddr,
            licenseType: user.licenseType,
            locale: user.locale,
          })
          contextMethod('user.accessLevel', user.accessLevel)
        },
        (err) => {
          // using DD_RUM error tracking
          window.DD_RUM.addError(err)
        }
      )
  })
}
