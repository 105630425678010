const queuedEvents = []

function isLaunchReady() {
  return window.__satelliteLoaded
}

let trackFunction = (...args) => {
  if (isLaunchReady()) {
    flushQueue()
    window._satellite?.track?.(...args)
  } else {
    const queueInitialized = queuedEvents.length > 0
    if (!queueInitialized) {
      sendQueueWhenReady()
    }
    queuedEvents.push(args)
  }
}

function sendQueueWhenReady() {
  const intervalID = setInterval(function checkReadiness() {
    if (isLaunchReady()) {
      clearInterval(intervalID)
      flushQueue()
    }
  }, 1000)
}

function flushQueue() {
  queuedEvents.forEach((event) => {
    window._satellite?.track?.(...event)
  })
  queuedEvents.length = 0
}

/**
 * Send a Launch track event
 * @param {string} identifier   Identifier of the event to track
 * @param {Object} [data]       Data associated with the track event
 */
function trackLaunchEvent(identifier, data) {
  return trackFunction(identifier, data)
}

/**
 * Configure how Launch events are sent
 * @param {Function} customLaunchFunction   A function that accepts an identifier and data object and sends the event to Launch
 */
export function configureLaunchTrackFunction(fn) {
  trackFunction = fn
}

/**
 * Send a custom event to Launch
 * @param {string} identifier   Identifier of the event to track
 * @param {{ dimension1?: string, dimension2?: string, dimension3?: string, metric1?: number, metric2?: number }} [data]         Data associated with the track event
 */
export function trackLaunchCustomEvent(identifier, data) {
  return trackLaunchEvent('event', {
    action: 'custom_event',
    customVar: {
      type: identifier,
      ...data,
    },
  })
}

/**
 * Send a network_request event to Launch
 * @param {string} url   Pathname of the request, beginning with a slash
 * @param {string} method  The REST method used for the request (GET, POST, etc.)
 */
export function trackLaunchNetworkEvent(url, method) {
  return trackLaunchEvent('network_request', {
    request: url,
    method,
  })
}
