import { logAction, severity } from './datadogActions.js'

/**
 * Send a RUM action
 * @param {string} metricName   Name of action/metric
 * @param {object} data   Data associated with the action
 */
export function logRumEvent(metricName, data) {
  logAction(metricName, data, severity.info)
}
