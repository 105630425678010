import { logRumEvent } from './logRumEvent.js'

// Once more users are on Safari 17.4 or later we can switch to this
// const { promise, resolve } = Promise.withResolvers()
let _resolve
const promise = new Promise((resolve) => {
  _resolve = resolve
})

async function getIsUnifiedShellEnabled() {
  const { getIsUnifiedShellEnabled } = await import(
    '@wf-mfe/unified-shell-bootstrapper'
  )
  return getIsUnifiedShellEnabled()
}

export async function getShellMetrics() {
  const isUnifiedShellEnabled = await getIsUnifiedShellEnabled()

  if (isUnifiedShellEnabled) {
    return promise
  }
}

export async function pageDone() {
  const isUnifiedShellEnabled = await getIsUnifiedShellEnabled()

  if (isUnifiedShellEnabled) {
    const { default: page } = await import('@adobe/exc-app/page')
    logRumEvent('nwe_shell_done')
    _resolve(page.done())
  }
}
