const MFE_URL_REGEX = /https:\/\/[a-z-.]+\/[a-z-]+\/([a-z0-9]+)\/[a-z-.]+/
export default function getMFEVersion(mfeName) {
  const mfeUrl = getUrlFromSystemJS(mfeName)
  if (mfeUrl !== null) {
    return getVersionFromUrl(mfeUrl, mfeName)
  } else {
    return `unknown MFE - ${mfeName}`
  }
}

export function getVersionFromUrl(url, name) {
  // TODO add support for an mfe proxy url identification
  const match = MFE_URL_REGEX.exec(url)
  if (match && match[1]) {
    return match[1]
  } else if (url.includes('localhost') || url.includes('.dev.workfront.tech')) {
    return 'local'
  } else {
    return `unknown version - ${name}`
  }
}

function getUrlFromSystemJS(providedName) {
  const namesToTry = [providedName, `@wf-mfe/${providedName}`]
  return namesToTry.reduce((acc, name) => {
    try {
      const url = System.resolve(name)
      return url
    } catch (e) {
      return acc
    }
  }, null)
}
