import React from 'react'
import { createMFELogger } from './logger'
import { ErrorBoundary } from '../ErrorBoundary'

/**
 *
 * @typedef {Object} MFELoggerAndReactBoundaryReturn
 * @property {import('./logger').MFELogger} logger - A created MFE logger
 * @property {import('../ErrorBoundary').ErrorBoundary} ErrorBoundary - React Error boundary component that captures errors and renders children
 */

// I wasn't sure the best way to say "Everything from Error Boundary props, but make logger optional"
/**
 * @typedef {Object} UnboundErrorBoundaryProps
 * @prop {?import('../ErrorBoundary').ErrorBoundaryProps.MFELogger} MFELogger
 * @prop {import('../ErrorBoundary').ErrorBoundaryProps.FallbackComponent} FallbackComponent
 * @prop {import('../ErrorBoundary').ErrorBoundaryProps.onReset} onReset
 * @prop {import('../ErrorBoundary').ErrorBoundaryProps.onResetKeysChange} onResetKeysChange
 * @prop {import('../ErrorBoundary').ErrorBoundaryProps.onError} OnError
 * @prop {import('../ErrorBoundary').ErrorBoundaryProps.resetKeys} resetKeys
 * @prop {import('../ErrorBoundary').ErrorBoundaryProps.fallbackRender} fallbackRender
 */

/**
 * Creates a new MFE Logger as well as a React error boundary that is automatically bound to that created logger instance.
 * This allows for an error boundary that will automaticlaly report errors without the need to pass in the logger instance when you use it.
 * @param {import('./logger').MFELoggerConfig} config
 * @returns {MFELoggerAndReactBoundaryReturn}
 */
export function MFELoggerAndReactErrorBoundary(config) {
  const createdLogger = createMFELogger(config)
  const boundErrorBoundary = UnboundErrorBoundary.bind(null, createdLogger)
  return {
    logger: createdLogger,
    ErrorBoundary: boundErrorBoundary,
  }
}
/**
 * Creation of a react error boundary that supports currying
 * @param {import('./logger').MFELogger} logger
 * @param {UnboundErrorBoundaryProps} props
 */
function UnboundErrorBoundary(logger, props) {
  const { children, ...rest } = props
  return (
    <ErrorBoundary logger={logger} {...rest}>
      {children}
    </ErrorBoundary>
  )
}
