export function getJavaScriptResourceInfo() {
  const mfeJsFiles = performance
    ?.getEntriesByType('resource')
    .filter(
      ({ name }) =>
        name.includes('static.workfront.com') && name.includes('.js')
    )

  let resourceSize = 0
  // keeping track of h1 and h2 vs just h2 / total file count because sometimes
  // the  nextHopProtocol is '' (unknown?) on one or two resources
  // we really care about just h1 vs h2 so we can get customers off of h1
  let http1Count = 0
  let http2Count = 0

  for (const { decodedBodySize, nextHopProtocol } of mfeJsFiles) {
    if (decodedBodySize === 0) {
      resourceSize = undefined
      break
    }

    if (nextHopProtocol === 'h1') {
      http1Count++
    } else if (nextHopProtocol === 'h2') {
      http2Count++
    }

    resourceSize += decodedBodySize
  }

  return {
    http2Ratio: resourceSize
      ? http2Count / (http1Count + http2Count)
      : undefined,
    resourceSize,
    fileCount: mfeJsFiles.length,
  }
}
