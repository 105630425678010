/*
 * To Prevent hitting localstorage to Datadog we're caching the result and using that.
 * Exported as a function to make it easier to mock in our tests
 */
let cachedShouldLog, cachedIsDevMode
export function shouldLog() {
  if (cachedShouldLog !== undefined) {
    return cachedShouldLog
  } else {
    cachedShouldLog = shouldLogToDatadog__DO_NOT_USE()
    return cachedShouldLog
  }
}
export function isInDevMode() {
  if (cachedIsDevMode !== undefined) {
    return cachedIsDevMode
  } else {
    cachedIsDevMode = areDevToolsActive__DO_NOT_USE()
    return cachedIsDevMode
  }
}

/*
 * These are exported so we can test the logic of what gets cached above.
 * THESE SHOULD NOT BE USED DIRECTLY OUTSIDE THIS FILE AND THE TESTS
 * If these are used then we defeat the purpose of caching and we pay the cost of checking
 *  localstorage repeatedly.
 */
export function shouldLogToDatadog__DO_NOT_USE() {
  const devToolsInactive = !areDevToolsActive__DO_NOT_USE()
  const localStorageValue = localStorage.getItem('wf:log-with-devtools')
  const shouldLogWithDevTools = localStorageValue === 'true'

  return devToolsInactive || shouldLogWithDevTools
}

export function areDevToolsActive__DO_NOT_USE() {
  return localStorage.getItem('devtools') === 'true'
}
